import { Button, Center, Container, Grid, Paper, SimpleGrid, createStyles, Title, Text, useMantineTheme, Progress, Transition, LoadingOverlay, Group, Stack, Loader, Table, Select, Indicator } from '@mantine/core';
import { useEffect, useState } from 'react';

import FrequencyChart from '../../components/statistic/FrequencyChart';
import MetricTracker from '../../components/statistic/MetricTracker';
import IndoorTracker from '../../components/statistic/IndoorTracker';
import { useBilling } from '../../hooks/Billing/useBilling';
import { useAuthContext } from '../../hooks/useAuthContext';

export function Obračunavanje() {
	const [billingData, setBillingData] = useState<any>(null);
	const [totalSum, setTotalSum] = useState<number>(0);

	const { getBillingData } = useBilling();
  	const { user } = useAuthContext();
	//TODO: Dodati employee.name == "Administrator" i na backend 

	useEffect(() => {
		getBillingData().then((json_data) => {
			setBillingData(json_data.info);
		})
	}, []);

	const [selectedMonth, setSelectedMonth] = useState<string>('');
	const [selectedYear, setSelectedYear] = useState<string | null>('');

	const years = billingData ? Object.keys(billingData) : [];
	const months = selectedYear && billingData ? Object.keys(billingData[selectedYear]) : [];

	const selectedData = selectedYear && selectedMonth ? billingData[selectedYear][selectedMonth] : null;

	const renderTable = () => {
		if (!selectedData) return null;
		
		const daysInSelectedMonth = new Date(Number(selectedYear), Number(selectedMonth), 0).getDate();

		// Calculate total sum
		const total = Object.values(selectedData)
			.reduce((sum: number, item: any) => sum + Number(Number(item.subtotal).toFixed(2)), 0);

		return (
			<Table>
				<thead>
					<tr>
						<th>Dan</th>
						<th>Aktivnih korisnika</th>
						<th>Neaktivnih korisnika</th>
						<th>Mj. naknada (aktivni/neaktivni)</th>
						<th>Dnevna naknada (aktivni/neaktivni)</th>
						<th>Iznos</th>
					</tr>
				</thead>
				<tbody>
					{Object.entries(selectedData)
						.sort((a, b) => b[0].localeCompare(a[0]))
						.map(([day, item]: [string, any]) => (
							<tr key={day}>
								<td>{day}</td>
								<td>{item.activeUsers}</td>
								<td>{item.inactiveUsers}</td>
								<td>{Number(item.activeFee).toFixed(2)}€ | {Number(item.inactiveFee).toFixed(2)}€</td>
								<td>{Number(item.activeFee/daysInSelectedMonth).toFixed(9)}€ | {Number(item.inactiveFee/daysInSelectedMonth).toFixed(9)}€</td>
								<td>{Number(item.subtotal).toFixed(2)}€</td>
							</tr>
						))}
				</tbody>
				<tfoot>
					<tr style={{ borderTop: '1px solid #e6e6e6', marginTop: '2px' }}>
						<th>Ukupan iznos</th>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td>{total.toFixed(2)}€</td>
					</tr>
				</tfoot>
			</Table>
		);
	};

	return (
		(billingData && user.employee.name == "Administrator") ? (
			<>
				<Title m="md">Obračunavanje</Title>
				<Container my="md" ml="xs">
					<Group mb="md">
						<Select
							label="Godina"
							placeholder="Odaberite godinu"
							value={selectedYear}
							data={years}
							onChange={(value: string | null) => setSelectedYear(value || '')}
						/>
						<Select
							label="Mjesec"
							placeholder="Odaberi mjesec"
							value={selectedMonth}
							onChange={(value: string | null) => setSelectedMonth(value || '')}
							data={months}
							disabled={!selectedYear}
						/>
					</Group>
					{renderTable()}
				</Container>
			</>
		) :
			(<Center sx={{ height: "100%" }}>
				<Stack>
					<Text>Podaci se učitavaju.</Text>
					<Loader sx={{ width: "100%" }} />
				</Stack>
			</Center>)
	)
}

export default Obračunavanje