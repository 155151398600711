import { Button, Dialog, Indicator, Title, Modal } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useState } from "react";

interface SpecialisedDateModifier {
    handleDateChange: (date: Date | null) => void;
    dateDue: Date;
}

export default function SpecialisedDateModifier({ handleDateChange, dateDue }: SpecialisedDateModifier) {
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [confirmOrangeRange, setConfirmOrangeRange] = useState(false);

    const pickerDateChange = (date: Date | null) => {
        if (!date) return;
        date.setHours(23, 59, 0, 0);
        setSelectedEndDate(date);
        handleDateChange(date);
    };

    const DateRenderer = (date: Date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const isToday = date.toDateString() === today.toDateString();
        const isDue = date.toDateString() === dateDue.toDateString();

        if (isToday) {
            return (
                <Indicator size={15} position="top-center" radius="xl" label="DANAS" color="blue">
                    <div>{date.getDate()}</div>
                </Indicator>
            );
        }

        if (isDue) {
            return (
                <Indicator size={15} position="top-center" radius="xl" label="STARI ROK" color="rgba(255, 166, 0,1)">
                    <div>{date.getDate()}</div>
                </Indicator>
            );
        }

        if (selectedEndDate) {
            const isSelected = date.toDateString() === selectedEndDate.toDateString()
            if (isSelected) {
                return (
                    <Indicator size={15} position="top-center" radius="xl" label="NOVI ROK" color="blue">
                        <div>{date.getDate()}</div>
                    </Indicator>
                );
            }
        }

        if (!selectedEndDate || date < dateDue || date > selectedEndDate) {
            return <div>{date.getDate()}</div>;
        }

        return (
            <div>{date.getDate()}</div>
        );
    };

    const DayStyle = (date: Date) => {
        let style: React.CSSProperties = {};
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const isToday = date.toDateString() === today.toDateString();
        const isDue = date.toDateString() === dateDue.toDateString();

        if (selectedEndDate) {
            const isSelected = date.toDateString() === selectedEndDate.toDateString()
            const isBeforeToday = (selectedEndDate < today && date < today && date > selectedEndDate);
            const isAfterToday = (selectedEndDate > today && date > today && date < selectedEndDate);

            if (isAfterToday) {
                style.backgroundColor = "rgba(34, 230, 80, 0.2)"
                style.color = "white";
                style.borderRadius = "0px";
            } else if (isBeforeToday) {
                style.backgroundColor = "rgba(255, 0, 0, 0.2)"
                style.color = "white";
                style.borderRadius = "0px";
            }

            if (isSelected) {
                style.backgroundColor = 'rgba(34, 139, 230, 0.2)'; // Stronger blue background for due date
                style.color = "white";
                style.borderRadius = "4px";
                style.border = "2px solid #228be6"; // Slight red right border for due date
            }
        }

        const isStealing = ((date > today && date < dateDue));
        if (isStealing) {
            style.backgroundColor = 'rgba(255, 166, 0, 0.3)'
            style.color = "black"
        }

        if (isToday) {
            style.backgroundColor = "#228be6";
            style.color = "white";
        }

        if (isDue) {
            style.backgroundColor = "rgba(255, 166, 0, 1)";
            style.borderRadius = "4px";
            style.color = "white";
        }


        return style;
    };

    return (
        <>
            <Dialog opened={isDatePickerOpen} zIndex={1000}>
                <Title order={3} mb="xs">Pomoćni alati</Title>
                <Button mb="md" onClick={() => {
                    const twoWeeksFromNow = new Date();
                    twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 14);
                    twoWeeksFromNow.setHours(23, 59, 0, 0);
                    pickerDateChange(twoWeeksFromNow);
                }}>
                    Odaberi 2 tjedna od danas
                </Button>
                <Button onClick={() => {
                    const twoWeeksFromNow = dateDue;
                    twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 14);
                    twoWeeksFromNow.setHours(23, 59, 0, 0);
                    pickerDateChange(twoWeeksFromNow);
                }}>
                    Odaberi 2 tjedna od isteka roka
                </Button>
            </Dialog>

            {/* Confirmation Modal for Orange Range */}
            <Modal opened={confirmOrangeRange} onClose={() => setConfirmOrangeRange(false)} title="Potvrda raspona">
                <p>Odabrani datum isteka je prije početnog datuma. Jeste li sigurni?</p>
                <Button color="red" onClick={() => setConfirmOrangeRange(false)}>Odustani</Button>
                <Button ml="sm" onClick={() => {
                    setSelectedEndDate(selectedEndDate);
                    handleDateChange(selectedEndDate);
                    setConfirmOrangeRange(false);
                }}>Potvrdi</Button>
            </Modal>

            <DatePicker locale="hr"
                dropdownType="modal"
                hideWeekdays={false}
                modalZIndex={999}
                closeCalendarOnChange={false}
                dayStyle={DayStyle}
                renderDay={DateRenderer}
                placeholder="Ručno odabiranje datuma isteka"
                inputFormat="DD/MM/YYYY"
                defaultValue={dateDue}
                value={selectedEndDate || dateDue}
                onChange={pickerDateChange}
                onDropdownOpen={() => setIsDatePickerOpen(true)}
                onDropdownClose={() => setIsDatePickerOpen(false)}
                mt="xs"
                mb="xs"
            />
        </>
    );
}