import Članovi from "./DashboardPages/Članovi";
import Teretana from "./DashboardPages/Teretana";
import Statistika from "./DashboardPages/Statistika";
import Grupe from "./DashboardPages/Grupe";
import Logger from "./DashboardPages/Logger";
import Obračunavanje from "./DashboardPages/Obračunavanje";

export default function SideMenu({ data }: any) {
  switch (data) {
    case "teretana":
      return <Teretana />;
    case "članovi":
      return <Članovi />;
    case "statistika":
      return <Statistika />;
    case "grupe":
      return <Grupe />;
    case "zapisnik":
      return <Logger />;
    case "obračunavanje":
      return <Obračunavanje/>;
  }
  return <a>ERROR</a>;
}
