import { showSuccess, showError, showLoading, updateLoading } from '../../utils/showPopup';
import { getDefaultHeaders } from '../util/getRequestHeaders';

export const useBilling = () => {
    const getBillingData = async () => {
        const response = await fetch("/api/billing/billing_data", {
            method: "POST",
            headers: getDefaultHeaders(),
            body: JSON.stringify()
        })
        const json = await response.json()

        if (!response.ok) {
            showError(json.error)
            return {}
        } else if (response.ok) {
            return json
        }
    }
    return { getBillingData }
}