import { Button, Dialog, Indicator, Title } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { useState } from "react";

interface SpecialisedDatePickerProps {
    handleDateChange: (date: Date | null) => void;
    minimumDate?: Date | null;
    referenceDate?: Date | null;
}

export default function SpecialisedDatePicker({handleDateChange, minimumDate, referenceDate}: SpecialisedDatePickerProps) {
    const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

    const pickerDateChange = (date: Date | null) => {
        date?.setHours(23, 59)
        setSelectedEndDate(date);
        handleDateChange(date);
    }

    const DateRenderer = (date: Date) => {
        const due = selectedEndDate;
        const today = minimumDate || referenceDate || new Date();
        today.setHours(0, 0, 0, 0); // Normalize time to start of the day

        if (!due || date < today || date > due) {
            return <div>{date.getDate()}</div>;
        }

        // Calculate day counter starting from 1
        const dayCount = Math.ceil((date.getTime() - today.getTime()) / (1000 * 3600 * 24)) + 1;

        return (
            <Indicator size={15} offset={8} radius="xl" label={dayCount.toString()} color={dayCount > 0 ? "green" : "red"}>
                <div>{date.getDate()}</div>
            </Indicator>
        );
    };

    const DayStyle = (date: Date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Normalize to start of the day

        const isToday = date.toDateString() === today.toDateString();
        const isSelectedEndDate = selectedEndDate && date.toDateString() === selectedEndDate.toDateString();
        const isBetween = selectedEndDate && date > today && date < selectedEndDate;

        let style: React.CSSProperties = {};

        if (isBetween) {
            style.backgroundColor = 'rgba(34, 139, 230, 0.2)'; // Light blue background
            style.color = "white";
            style.borderRadius = "0px";
        }

        if (isToday) {
            style.backgroundColor = '#228be6'; // Stronger blue background for today
            style.color = "white";
        }

        if (isSelectedEndDate) {
            style.backgroundColor = 'rgba(34, 139, 230, 0.2)'; // Stronger blue background for due date
            style.color = "white";
            style.borderRadius = "4px";
            style.border = "2px solid #228be6"; // Slight red right border for due date
        }

        return style; // Leaves all other dates unchanged
    }

    return (
        <>
            <Dialog opened={isDatePickerOpen} zIndex={1000}>
                <Title order={3} mb="xs">Pomoćni alati</Title>
                <Button onClick={() => {
                    const twoWeeksFromNow = new Date();
                    twoWeeksFromNow.setDate(twoWeeksFromNow.getDate() + 14);
                    twoWeeksFromNow.setHours(23, 59, 0, 0);
                    pickerDateChange(twoWeeksFromNow);
                }}>
                    Odaberi 2 tjedna od danas
                </Button>
            </Dialog>
            <DatePicker locale="hr"
                minDate={minimumDate ? minimumDate : undefined}
                dropdownType="modal"
                hideWeekdays={false}
                modalZIndex={999}
                closeCalendarOnChange={false}
                dayStyle={DayStyle}
                renderDay={DateRenderer}
                placeholder="Ručno odabiranje datuma isteka"
                inputFormat="DD/MM/YYYY"
                value={selectedEndDate}
                onChange={pickerDateChange}
                onDropdownOpen={() => setIsDatePickerOpen(true)}
                onDropdownClose={() => setIsDatePickerOpen(false)}
                mb="xs"
            />
        </>
    )
}