import { Button, Group, Modal, TextInput, SimpleGrid, Text, NumberInput, Dialog, Select, MultiSelect, Stack, Stepper, Title, Tooltip, ActionIcon, Indicator, Tabs, Input, SegmentedControl } from "@mantine/core";
import { useForm } from "@mantine/form";
import { IconCalendar, IconCamera, IconCameraSelfie, IconClipboard, IconClipboardData, IconNumbers, IconUser, IconUserPlus } from "@tabler/icons-react";
import { useRef, useEffect, useState } from "react";

import ImageDisplayOrUpload from "./ImageDisplayOrUpload";
import { useRegister } from "../hooks/useRegister";
import { DatePicker } from "@mantine/dates";
import {
  GetDateInMonths,
  DateToString,
  DateToStringPrecise,
} from "../utils/dateManager";
import "dayjs/locale/hr";
import { useGetAllGroups } from "../hooks/useMemberGroups";
import { createAccounts } from "../hooks/test/createTestUsers";
import SpecialisedDatePicker from "./DateAndTime/SpecialisedDatePicker";
import { showError } from "../utils/showPopup";
// import { createAccounts } from "../hooks/test/createTestUsers";

interface FormValues {
  name: string;
  surname: string;
  email: string;
  date_birth: Date | null;
  // date_due: Date, Set by backend,
  // date_joined: Date, Set by backend
  phone_number: string;
  image: any;
  membership_type: string;
  membership_months: number;
  membership_date: Date | null;
  rfid: string;
}

const EmptyFormValues = {
  name: "",
  surname: "",
  email: "",
  date_birth: null,
  phone_number: "",
  image: "",
  membership_type: "",
  membership_months: 1,
  membership_date: null,
  rfid: ""
}

function CreateUserModal({ callback }: any) {
  const [opened, setOpened] = useState(false);
  const [date, setDate] = useState("");
  const { register } = useRegister();
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [turned, setTurned] = useState(false);
  const [capturedFile, setCapturedFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState("/img/user_default.png");

  const [weeklyChoice, setWeeklyChoice] = useState(Object);
  const [monthlyChoice, setMonthlyChoice] = useState(Boolean);
  const [nacinUnosa, setNacinUnosa] = useState("brojcano");
  const [povratnaInformacija, setPovratnaInformacija] = useState("");
  const [blockSending, setBlockSending] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const nextStep = () =>
    setActiveStep((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActiveStep((current) => (current > 0 ? current - 1 : current));

  const [selectedGroups, setSelectedGroups] = useState<(string | undefined)[]>(
    []
  );
  const [allGroups, setAllGroups] = useState([]);
  const { getAll } = useGetAllGroups();

  let userForm = useForm<FormValues>({
    initialValues: EmptyFormValues
  });

  useEffect(() => {
    getAll(true).then((data) => {
      var groups = data.map(
        (group: { _id: string; name: string; color: string }) => ({
          value: group._id,
          label: group.name,
          color: group.color,
        })
      );
      setAllGroups(groups);
    });
  }, []);

  useEffect(() => {
    userForm.setValues(EmptyFormValues);
    setBlockSending(true);
    setPovratnaInformacija("")
  }, [opened]);

  useEffect(() => {
    async function getCameraStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (err) {
        console.error("Error accessing camera: ", err);
      }
    }

    if (turned) {
      getCameraStream();
    } else {
      stopCamera();
    }
    return () => {
      stopCamera();
    };
  }, [turned]);

  const stopCamera = () => {
    console.log("Stopping camera");
    if (videoRef.current && videoRef.current.srcObject) {
      console.log("Stopping camera stream");
      const stream = videoRef.current.srcObject as MediaStream;
      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => {
          console.log(`Stopping track: ${track.kind}`);
          track.stop();
        });
      }
      videoRef.current.srcObject = null;
    } else {
      console.log("No camera stream to stop");
    }
  };

  const capturePicture = () => {
    if (videoRef.current && canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      if (context) {
        canvas.width = videoRef.current.videoWidth;
        canvas.height = videoRef.current.videoHeight;
        context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
        canvas.toBlob((blob) => {
          if (blob) {
            const file = new File([blob], "captured.png", {
              type: "image/png",
            });

            setCapturedFile(file);
            setFileUrl(URL.createObjectURL(file));
            userForm.setFieldValue("image", file); // Save the captured file to the form
            setTurned(false); // Turn off the camera after capturing
            stopCamera(); // Stop the camera stream
          }
        }, "image/png");
      }
    }
  };

  const handleSubmit = async (values: any) => {
    if (blockSending) { showError("Popunite obavezna polja!"); return }

    const full_name = values.name + " " + values.surname;

    if (
      (await register(
        full_name,
        values.email,
        values.date_birth,
        values.phone_number,
        values.image,
        weeklyChoice,
        values.membership_months,
        selectedGroups,
        values.membership_date,
        values.rfid
      )) === true
    ) {
      setOpened(false);
      setTurned(false);
      setCapturedFile(null);
      callback();
    }
  };

  function setImageValue(files: any) {
    userForm.setFieldValue("image", files[0]);
  }

  const handleMonthChange = (months: number) => {
    if (months >= 1) {
      let membership_due = GetDateInMonths(months);
      let membership_due_string = DateToStringPrecise(membership_due);
      setPovratnaInformacija(`Članstvo do ${membership_due_string}`);
      userForm.setFieldValue("membership_months", months);
      userForm.setFieldValue("membership_date", null);
      setBlockSending(false);
    } else {
      setPovratnaInformacija("Neispravan broj mjeseci.");
      setBlockSending(true);
    }
    
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      let membership_due_string = DateToStringPrecise(date);
      setPovratnaInformacija(`Članstvo do ${membership_due_string}`);
      userForm.setFieldValue("membership_date", date);
      userForm.setFieldValue("membership_months", 0);  
      setBlockSending(false);
    } else {
      setPovratnaInformacija("Molimo odaberite datum");
      setBlockSending(true);
    }
  };

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title="Izrada korisnika"
        size="50vw"
        centered={true}
      >
        <Stepper
          active={activeStep}
          onStepClick={setActiveStep}
          breakpoint="sm"
        >
          <Stepper.Step
            label="Fotografija"
            description="Dodajte fotografiju korisnika"
          >
            <Title mt="md" size={24}>
              Fotografija
            </Title>
            <Text mb="lg" c="dimmed" size={14}>
              Prenesite fotografiju s računala ili uslikajte web-kamerom!
            </Text>
            {turned ? (
              <>
                <video
                  ref={videoRef}
                  autoPlay
                  style={{ width: "100%", height: "auto" }}
                />
                <Button
                  leftIcon={<IconCameraSelfie />}
                  onClick={capturePicture}
                  style={{ width: "100%", marginTop: "10px" }}
                  color="red"
                >
                  Uslikaj sliku
                </Button>
              </>
            ) : (
              <ImageDisplayOrUpload
                src={
                  capturedFile
                    ? URL.createObjectURL(capturedFile)
                    : "/img/user_default.png"
                }
                successCallback={setImageValue}
              />
            )}
            <Button
              style={{ width: "100%", marginTop: "10px" }}
              leftIcon={<IconCamera />}
              onClick={() =>
                turned == true
                  ? (stopCamera(), setTurned(!turned))
                  : setTurned(!turned)
              }
            >
              {turned ? "Ugasi" : "Upali"} kameru
            </Button>
            <canvas ref={canvasRef} style={{ display: "none" }} />
          </Stepper.Step>
          <Stepper.Step
            label="Osnovni podatci"
            description="Ispunite osnovne podatke"
          >
            <form
              onSubmit={userForm.onSubmit((values) => handleSubmit(values))}
            >
              <Stack spacing="md">
                <Title mt="lg" size={24}>
                  Osnovni podatci
                </Title>
                <SimpleGrid cols={2} spacing="md">
                  <TextInput
                    withAsterisk
                    label="Ime"
                    placeholder="Pero"
                    {...userForm.getInputProps("name")}
                  />

                  <TextInput
                    withAsterisk
                    label="Prezime"
                    placeholder="Perić"
                    {...userForm.getInputProps("surname")}
                  />
                </SimpleGrid>

                <TextInput
                  label="Email"
                  placeholder="imeprezime@email.com"
                  {...userForm.getInputProps("email")}
                />

                <TextInput
                  label="Broj telefona"
                  placeholder="+385"
                  {...userForm.getInputProps("phone_number")}
                />

                <DatePicker
                  withAsterisk
                  placeholder="Odaberi datum"
                  label="Datum rođenja"
                  inputFormat="DD/MM/YYYY"
                  labelFormat="MM/YYYY"
                  defaultValue={new Date()}
                  locale="hr"
                  {...userForm.getInputProps("date_birth")}
                />
              </Stack>
            </form>
          </Stepper.Step>
          <Stepper.Step
            label="Članstvo"
            description="Potvrdite detalje članstva"
          >
            <form
              onSubmit={userForm.onSubmit((values) => handleSubmit(values))}
            >
              <Stack>
                <Group position="center" spacing="md" grow>
                  <Stack>
                    <Title mt="lg" size={24}>
                      Članstvo
                    </Title>
                    <Select
                      withAsterisk
                      label="Postavke ograničenja"
                      data={[
                        { value: "2xtjedno", label: "2x tjedno" },
                        { value: "3xtjedno", label: "3x tjedno" },
                        { value: "neograniceno", label: "neograničeno" },
                        { value: "jutarnja", label: "jutarnja (do 14h)" },
                      ]}
                      onChange={(value) => setWeeklyChoice(value)}
                    />
                    <TextInput
                      label={
                        // sa asteriskom od njih ne radi pa ako znas kako da bolje napravim slobodno
                        <Group position="apart">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Text>Broj kartice </Text>
                          </div>
                        </Group>
                      }
                      rightSection={
                        <Tooltip label="Zaljepite broj iz međuspremnika">
                          <ActionIcon
                            // variant="filled" 
                            color="dark"
                            size={30}
                            style={{ marginBottom: 5 }}
                            onClick={() => {
                              navigator.clipboard.readText().then((text) => {
                                userForm.setFieldValue("rfid", text);
                              });
                            }}
                          >
                            <IconClipboard />
                          </ActionIcon>
                        </Tooltip>}
                      placeholder="*1234567890"
                      {...userForm.getInputProps("rfid")}
                    />

                    <Input.Wrapper
                      id="date-due-picker"
                      withAsterisk
                      label="Odabir datuma isteka članarine"
                      description="Molimo unesite na koji način i koliko dugo želite učlaniti ovu osobu"
                      error={povratnaInformacija}
                    >
                      <SegmentedControl
                        size="xs"
                        value={nacinUnosa}
                        onChange={(value)=>{setNacinUnosa(value); setPovratnaInformacija("")}}
                        data={[
                          { label: 'Učlani na broj mjeseci', value: 'brojcano' },
                          { label: 'Ručno postavi datum isteka', value: 'rucno' },
                        ]}
                        mt="xs"
                      />

                      {nacinUnosa === 'brojcano' ? (
                        <NumberInput
                          id="input-date-due-brojcano"
                          min={1}
                          max={24}
                          placeholder="Unesite broj mjeseci"
                          rightSectionWidth="auto"
                          onChange={handleMonthChange}
                          stepHoldDelay={500}
                          stepHoldInterval={100}
                          mb="xs"
                        />
                      ) : (
                        <SpecialisedDatePicker handleDateChange={handleDateChange} minimumDate={new Date()}/>
                      )}
                    </Input.Wrapper>

                    {monthlyChoice ? (
                      <NumberInput
                        // withAsterisk
                        min={1}
                        max={12}
                        description={date}
                        label={
                          // sa asteriskom od njih ne radi pa ako znas kako da bolje napravim slobodno
                          <Group position="apart">
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Text>Učlani na broj mjeseci </Text>
                              <Text style={{ marginLeft: 5, color: "red" }}>
                                *
                              </Text>
                            </div>
                            <Tooltip label="Ručno određivanje pomoću kalendara">
                              <ActionIcon
                                // variant="filled"
                                color="dark"
                                size={30}
                                style={{ marginBottom: 5 }}
                                onClick={() => {
                                  setMonthlyChoice(false);
                                }}
                              >
                                <IconCalendar />
                              </ActionIcon>
                            </Tooltip>
                          </Group>
                        }
                        placeholder="Broj mjeseci"
                        onChange={handleMonthChange}
                      // {...userForm.getInputProps('membership_months')}
                      />
                    ) : null}
                    {/*<DatePicker
                       locale="hr"
                       minDate={new Date()}
                       dropdownType="modal"
                       hideWeekdays={false}
                       dayStyle={(date) => {
                        if (date > new Date() && userForm.values.membership_date && (date < userForm.values.membership_date)) {
                            return { backgroundColor: 'rgba(34, 139, 230, 0.2)', color: "white", borderRadius: "0px" };
                          } else if (date.toDateString() === new Date().toDateString()) {
                          return { backgroundColor: '#228be6', color: "white" };
                        }
                        return {};
                      }}
                      renderDay={DateRenderer}
                      label={
                        // sa asteriskom od njih ne radi pa ako znas kako da bolje napravim slobodno
                        <Group position="apart">
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Text>Odabir datuma isteka članarine</Text>
                            <Text style={{ marginLeft: 5, color: "red" }}>
                              *
                            </Text>
                          </div>
                          <Tooltip label="Određivanje pomoću broja mjeseci">
                            <ActionIcon
                              variant="filled"
                              color="dark"
                              size={30}
                              style={{ marginBottom: 5 }}
                              onClick={() => {
                                setMonthlyChoice(true);
                              }}
                            >
                              <IconCalendar />
                            </ActionIcon>
                          </Tooltip>
                        </Group>
                      }
                      placeholder="Ručno odabiranje datuma isteka"
                      inputFormat="DD/MM/YYYY"
                      onChange={handleDateChange}
                    />
                  )}*/}
                    <MultiSelect
                      label={"Dodavanje u grupe"}
                      data={allGroups}
                      mb="lg"
                      maxSelectedValues={3}
                      onChange={setSelectedGroups}
                    />
                  </Stack>
                </Group>
                <Group position="center" mt="md">
                  <Button type="submit" color="green">
                    Izradi korisnika
                  </Button>
                </Group>
              </Stack>
            </form>
          </Stepper.Step>
        </Stepper>

        {/*<Box mx="auto">
          
        </Box>*/}
        <Group position="center" mt="xl">
          {activeStep > 0 ? (
            <Button variant="default" onClick={prevStep}>
              Nazad
            </Button>
          ) : null}
          {activeStep < 2 ? <Button onClick={nextStep}>Dalje</Button> : null}
        </Group>
      </Modal>

      <Tooltip label="Izrada korisnika" position="top" withArrow>
        <ActionIcon
          onClick={() => {
            setOpened(true);
            setActiveStep(0);
          }}
          size="lg"
          variant="filled"
          color="green"
        >
          <IconUserPlus size="24" />
        </ActionIcon>
      </Tooltip>
    </>
  );
}

export default CreateUserModal;

