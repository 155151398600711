import { Center, Container, Accordion, createStyles, Highlight, Group, Image, Text, Title, List, Button } from "@mantine/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function PrivacyPolicy() {
  const useStyles = createStyles((theme) => ({
    footer: {
      backgroundColor: 'black',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      maxHeight: "8vh",
      borderTop: "1px solid white", // 228bff
      padding: theme.spacing.xl,
    },
  }));

  const { classes } = useStyles();
  const navigate = useNavigate();
  return (
    <>
      <Container size="sm" >
        <Title align="center" mt="lg">
          Politika privatnosti
        </Title>
        <Center mb="lg">        
          <Text size="xs" color="grey">Zadnja izmjena: 14.02.2025.</Text>
        </Center>

        <Accordion variant="separated">
          <Accordion.Item value="stavka1">
            <Accordion.Control>Vrste osobnih podataka koji se prikupljaju</Accordion.Control>
            <Accordion.Panel>
              <Title size="xs">Teretane</Title>
              <List>
                <List.Item>Email adresa</List.Item>
                <List.Item>Naziv, opis, adresa i radno vrijeme</List.Item>
              </List>
              <Title size="xs" mt="md">Članovi teretana</Title>
              <List>
                <List.Item>Podatci ustupljeni prilikom upisa u teretanu (fotografija, ime, prezime, datum rođenja, kontakt informacije) </List.Item>
                <List.Item>Podatci o statusu članstva u teretani (početak članstva, rok članstva)</List.Item>
              </List>

            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="stavka2">
            <Accordion.Control>Svrha i pravni temelj za obradu osobnih podataka</Accordion.Control>
            <Accordion.Panel>
              <Text>
                Obrađujemo osobne podatke članova teretane u svrhu vođenja evidencije članstva, upravljanja
                pristupom objektu te pružanja povezanih usluga. Obrada se temelji na:
              </Text>
              <List mt="md">
                <List.Item>Izvršenju ugovora - Podaci su nužni za ispunjenje obveza iz ugovora o članstvu.</List.Item>
                <List.Item>Legitimnom interesu - Vođenju evidencije i zaštitom imovine od neovlaštenog pristupa.</List.Item>
              </List>
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="stavka3">
            <Accordion.Control>Primatelji osobnih podataka</Accordion.Control>
            <Accordion.Panel>
              <Text>Podaci nužni za pravilno funkcioniranje usluge pohranjeni su na udaljenom poslužitelju,
                pri čemu treća strana koja pruža uslugu pohrane nema pristup sadržaju tih podataka niti ih koristi u vlastite svrhe.
              </Text>
              <Highlight highlight="ne prodaju se niti dijele" mt="md">
                Određene informacije mogu se dijeliti s trećim stranama isključivo kada je to nužno za poštivanje zakonskih propisa.
                Međutim, podaci vezani za teretane i njihove članove ne prodaju se niti dijele s
                oglašivačima ili drugim neovlaštenim subjektima.
              </Highlight>
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="stavka4">
            <Accordion.Control>Vaša prava</Accordion.Control>
            <Accordion.Panel>
              <Text>U bilo kojem trenutku slobodni ste nas kontaktirati u svrhu ostvarivanja svojih prava iz Opće uredbe o zaštiti podataka. Vaša prava su slijedeća:</Text>
              <List mt="md">
                <List.Item>Pravo pristupa</List.Item>
                <List.Item>Pravo povlačenja privole</List.Item>
                <List.Item>Pravo na ispravak osobnih podataka</List.Item>
                <List.Item>Pravo na brisanje</List.Item>
                <List.Item>Pravo prigovora</List.Item>
                <List.Item>Pravo na ograničenje obrade</List.Item>
                <List.Item>Pravo na prijenos podataka</List.Item>
              </List>
              <Title size="xs" mt="md">Kako podnijeti zahtjev?</Title>
              <Text>
                Ukoliko želite iskoristiti bilo koje od navedenih prava, slobodno nas kontaktirajte na info@gymchain.fit. Na vaš ćemo zahtjev odgovoriti u najkraćem mogućem roku, a najkasnije mjesec dana od primitka vašeg zahtjeva. U slučaju nemogućnosti sigurne potvrde vašeg identiteta bit ćemo slobodni zatražiti dodatnu provjeru vašeg identiteta.
              </Text>
              <Title size="xs" mt="md">Voditelj obrade</Title>
              <Text>
                MCMI, obrt za usluge, vl. Marija Ivić, Satnica Đakovačka, Josipa bana Jelačića 86
              </Text>
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="stavka5">
            <Accordion.Control>Sigurnost podataka</Accordion.Control>
            <Accordion.Panel><Text>Osiguravamo da su vaši podaci u najvećoj mogućoj mjeri zaštićeni od neovlaštenog pristupa provođenjem tehničkih mjera:</Text>
              <List mt="md">
                <List.Item>Sav promet (prijenos datoteka) između ove internetske stranice i vašeg internetskog preglednika je enkriptiran i isporučen putem HTTPS razine, uz osiguranje najviše razine sigurnosti podataka.</List.Item>
                <List.Item>Osjetljivi podaci vezani za pristup našem Servisu zaštićeni su enkripcijom.</List.Item>
                <List.Item>Vremenskim ograničavanjem pristupu sustava našeg Servisa.</List.Item>
              </List>
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value="stavka6">
            <Accordion.Control>Korištenje kolačića</Accordion.Control>
            <Accordion.Panel><Text>Ova stranica prikuplja isključivo kolačiće nužne za ostvarivanje funkcionalnosti Servisa.</Text>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Center>
          <Button mt="30%" onClick={()=>{navigate("/");}}>
            Povratak na početnu stranicu
          </Button>
        </Center>
      </Container>

      <div className={classes.footer}>
        <Group position="apart">
          <Image
            src="img/logo_long.png"
            style={{ width: "100%", maxWidth: "150px", marginLeft: "0" }}
          />
          <Text color="white">
            {new Date().getFullYear()} @ Đakovo, Hrvatska
          </Text>
        </Group>
      </div>
    </>
  )
}
